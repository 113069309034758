<template>
  <div>
    <modal-opinion-postulante :estado-convocatoria="dataConvocatoria.estado" />
    <ModalFormulario />
    <b-card class="mt-1">
      <div>
        <b-table
          id="my-table"
          ref="refTablaPostulantesPorEvaluar"
          class="position-relative"
          show-empty
          head-variant="light"
          hover
          small
          outlined
          responsive
          :busy.sync="isBusy"
          :items="myProvider"
          :fields="fields"
          :current-page="currentPage"
          :empty-text="'No hay registros para mostrar'"
          :empty-filtered-text="
            'No hay registros que coincidan con su solicitud.'
          "
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :no-provider-filtering="true"
          :tbody-tr-class="['p-2']"
          caption-top
        >
          <template #table-caption>
            <b-row>
              <b-col cols="4">
                <span class="text-primary"
                  ><strong>POSTULANTES ( Total: {{ cantPostulantes }} )</strong>
                </span>
              </b-col>
              <b-col cols="8" class="d-flex justify-content-end">
                <div class="mr-1">
                  <label>Mostrar</label>
                  <b-form-select
                    v-model="perPage"
                    :options="pageOptions"
                    :clearable="false"
                    size="sm"
                    style="width:auto"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>registros</label>
                </div>
                <div>
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Buscar..."
                    size="sm"
                  />
                </div>
              </b-col>
            </b-row>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong> cargando...</strong>
            </div>
          </template>

          <template #cell(formularios)="row" class="py-2">
            <div
              v-for="(val, index) in dataConvocatoria.requisitosx"
              :key="index"
              class="text-center"
            >
              <b-button
                class="mb-1"
                size="sm"
                variant="outline-primary"
                @click="
                  abrirModalFormulario(
                    row.item.usuario_id,
                    val.formulario_espejo_id
                  )
                "
              >
                <span style="font-size:10px">
                  {{ val.nombre }}
                </span>
              </b-button>
              <br />
            </div>
          </template>

          <template #cell(postulante)="row">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :variant="`light-primary`"
                  :text="
                    avatarText(recorteName(row.item.persona.nombre_completo))
                  "
                />
              </template>
              <b-link class="font-weight-bold d-block text-nowrap">
                {{ row.item.persona.nombre_completo }}
              </b-link>
              <small
                ><strong>ETNIA:</strong>
                {{
                  row.item.persona.pueblo_indigena &&
                    row.item.persona.pueblo_indigena.nombre
                }}</small
              >
              <br />
              <small><strong>EAP:</strong> {{ row.item.escuela.nombre }}</small>
            </b-media>
          </template>

          <!-- Column: servicios postulante-->
          <template #cell(servicios_postulante)="row">
            <div
              v-for="(xx, index) in row.item.servicios"
              :key="index"
              class="text-nowrap"
            >
              <b-row>
                <b-col cols="8" class="d-flex align-items-center">
                  <span class="text-align-left">
                    <feather-icon
                      :icon="resolveServicioIcon(xx.codigo)"
                      size="15"
                      class="mr-50"
                    />
                    <span style="font-size: 13px">{{ xx.nombre }} </span></span
                  >
                </b-col>
              </b-row>
            </div>
          </template>
          <template #cell(hay_opinion)="row">
            <feather-icon
              v-if="
                row.item.opinion_ficha_social !== null &&
                  row.item.opinion_ficha_social !== ''
              "
              icon="ThumbsUpIcon"
              size="18"
              class="mr-50 text-success"
            />
            <feather-icon
              v-else
              icon="ThumbsDownIcon"
              size="18"
              class="mr-50 text-danger"
            />
          </template>
          <template #cell(evaluar)="row">
            <b-button
              size="sm"
              variant="primary"
              @click="emitirOpinion(row.item)"
            >
              Opinión
              <feather-icon icon="EditIcon" size="16" />
            </b-button>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Mostrando registros del {{ from }} al {{ to }} de un total de
                {{ totalRows }} registros</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BSpinner,
  BRow,
  BCol,
  BPagination,
  BFormInput,
  BButton,
  BMedia,
  BAvatar,
  BLink,
  BFormSelect,
} from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import postulantesStoreModule from "./opb_postulantesStoreModule";
import ModalOpinionPostulante from "./ModalOpinionPostulante.vue";
import ModalFormulario from "./ModalFormulario.vue";

export default {
  components: {
    ModalOpinionPostulante,
    ModalFormulario,
    BCard,
    BTable,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BFormSelect,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 50],
      sortBy: "id",
      sortDesc: false,
      // sortDirection: 'desc',
      filter: "",
      filterOn: [],
      dataConvocatoria: {},
      dataPostulante: {},

      timeout: null,
      postulantes: {},

      cantPostulantes: 0,
    };
  },
  created() {
    this.$parent.$on(
      "actualizarTablaPostulantesPorEvaluar",
      this.actualizarTablaPostulantesPorEvaluar
    );
    store
      .dispatch(
        "postulantes-por-evaluar/getConvocatoria",
        this.$router.currentRoute.params.id
      )
      .then((res) => {
        this.dataConvocatoria = res.data;
      });
  },
  methods: {
    emitirOpinion(idPostulante) {
      this.$emit("emitirOpinion", idPostulante);
    },
    actualizarTablaPostulantesPorEvaluar() {
      this.filter = null;
      this.$refs.refTablaPostulantesPorEvaluar.refresh();
    },

    async myProvider(ctx) {
      const promise = this.$http.get(
        `/servicio-social/beca/convocatoria/${this.$router.currentRoute.params.id}/postulante`,
        { params: ctx }
      );
      return promise.then((response) => {
        const { total, from, to } = response.data;
        const { data } = response.data;

        this.postulantes = response.data.data;
        // data = data.filter(postulante => postulante.evaluado === 0)
        this.cantPostulantes = data.length;
        this.$emit("asignarCantPostulantesPorEvaluar", this.cantPostulantes);
        const items = data;
        this.totalRows = total || 0;

        this.from = from || 0;
        this.to = to || 0;

        return items || [];
      });
    },

    recorteName(name) {
      let recorte = [];
      recorte = name.split(" ");
      return `${recorte[0]} ${recorte[1]}`;
    },

    abrirModalFormulario(usuarioId, formularioId) {
      this.$emit("abrirModalFormularioRevision", { usuarioId, formularioId });
    },
  },
  setup() {
    const POSTULANTES_APP_STORE_MODULE_NAME = "postulantes-por-evaluar";

    // Register module
    if (!store.hasModule(POSTULANTES_APP_STORE_MODULE_NAME))
      store.registerModule(
        POSTULANTES_APP_STORE_MODULE_NAME,
        postulantesStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(POSTULANTES_APP_STORE_MODULE_NAME))
        store.unregisterModule(POSTULANTES_APP_STORE_MODULE_NAME);
    });

    const fields = [
      { key: "postulante", label: "POSTULANTE", sortable: true },
      {
        key: "servicios_postulante",
        label: "SERVICIOS",
        sortable: true,
      },
      { key: "formularios", label: "Requisitos", sortable: true },
      {
        key: "promedio_ponderado",
        label: "PROMEDIO PONDERADO",
        sortable: true,
      },
      { key: "hay_opinion", label: "", sortable: true },
      {
        key: "evaluar",
        label: "OPINIÓN",
        class: "text-center",
        sortable: true,
      },
    ];

    const resolveServicioVariant = (value) => {
      if (value === "BECA-COMEDOR") return "primary";
      if (value === "BECA-RESIDENCIA") return "primary";
      if (value === "BECA-BOLSA") return "primary";
      return "primary";
    };
    const resolveServicioIcon = (value) => {
      if (value === "BECA-COMEDOR") return "CoffeeIcon";
      if (value === "BECA-RESIDENCIA") return "HomeIcon";
      if (value === "BECA-BOLSA") return "BriefcaseIcon";

      return "UserIcon";
    };

    return {
      // Filter
      avatarText,
      fields,
      postulantesStoreModule,
      resolveServicioVariant,
      resolveServicioIcon,
    };
  },
};
</script>
<style lang="scss">
.colorEvaluacion {
  background-color: rgb(255, 255, 255);
}
.colorEvaluacion:hover {
  background-color: rgb(248, 252, 229);
}
</style>
