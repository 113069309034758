<template>
  <div>
    <b-modal
      id="modal_opinion_postulante"
      title="Opinión postulante"
      size="lg"
      no-close-on-backdrop
      :hide-footer="true"
      no-close-on-esc
      hide-header-close
    >
      <b-card
        no-body
        class="border mb-1 pr-1 mt-0"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              class="text-primary"
              icon="UserIcon"
              size="16"
            />
            <span
              class="text-primary align-middle ml-50"
              style="font-size:14px"
            >Postulante: </span> <span style="font-size:14px">{{ postulante.persona.nombre_completo }}</span>
            <br>
            <feather-icon
              class="text-primary"
              icon="FileTextIcon"
              size="16"
            />
            <span
              class="text-primary align-middle ml-50"
              style="font-size:14px"
            >Opinión postulante: </span>
          </b-card-title>
        </b-card-header>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-card-body>
            <b-form
              @submit.prevent="handleSubmit(formSubmit)"
            >
              <b-row>
                <b-col
                  lg="12"
                  md="12"
                  xs="12"
                  class="mb-1"
                >
                  <validation-provider
                    #default="validationContext"
                    name="opinion postulante"
                    rules="required"
                  >
                    <b-form-textarea
                      v-if="estadoConvocatoria === 'vigente' || estadoConvocatoria === 'en curso'"
                      v-model="postulante.opinion_ficha_social"
                      :placeholder="'Ingrese su opinión aquí'"
                      rows="8"
                      :state="getValidationState(validationContext)"
                    />
                    <div
                      v-else
                      class="p-2 border"
                    >
                      <label for="">
                        {{ postulante.opinion_ficha_social }}
                      </label>
                    </div>
                    <b-form-invalid-feedback v-if="validationContext.errors[0]">
                      <span class="text-danger">Este campo es requerido</span>
                    </b-form-invalid-feedback>

                  </validation-provider>
                </b-col>

                <b-col
                  lg="12"
                  md="12"
                  xs="12"
                >
                  <b-button
                    variant="primary"
                    class=" px-1"
                    size="sm"
                    style="padding: 8px 10px"
                    block
                    type="submit"
                    :disabled="estadoConvocatoria !== 'vigente' && estadoConvocatoria !== 'en curso' ? true : false"
                  >
                    GUARDAR OPINIÓN
                    <feather-icon
                      icon="SaveIcon"
                      size="14"
                      class="m-0 p-0"
                    />
                  </b-button>
                </b-col>

              </b-row>
            </b-form>
          </b-card-body>
        </validation-observer></b-card>

      <b-button
        variant="outline-danger"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$bvModal.hide('modal_opinion_postulante')"
      >
        Cerrar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
// import { ref } from '@vue/composition-api'
import {
  BCard, BRow, BCol, BButton, BForm, BCardHeader, BCardTitle, BCardBody, BFormTextarea, BFormInvalidFeedback,
} from 'bootstrap-vue'
// import store from '@/store'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import comunicadosStoreModule from './comunicadoStoreModule'

export default {
  components: {
    BFormInvalidFeedback,
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    estadoConvocatoria: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      postulante: {
        persona: {
          nombre_completo: null,
        },
      },
    }
  },
  created() {
    this.$parent.$on('emitirOpinion', this.emitirOpinion)
  },
  methods: {
    emitirOpinion(dataPostulante) {
      this.postulante = dataPostulante
      this.$bvModal.show('modal_opinion_postulante')
    },

    formSubmit() {
      this.$http.put(`/servicio-social/asistencia-social/opinion-ficha-social-postulante/${this.postulante.id}`, { opinion_ficha_social: this.postulante.opinion_ficha_social })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Opinión guardado!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('modal_opinion_postulante')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Falló el guardado, error 500',
              icon: 'XOctagonIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
