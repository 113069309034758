<template>
  <div>
    <b-card
      class="m-0 py-0 px-2"
    >
      <b-row
        class="m-0 p-0"
      >
        <b-col
          lg="12"
          xl="12"
          md="12"
          sm="12"
          xs="12"
          class="m-0 p-0"
        >
          <div class="">
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="42"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="FeatherIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <div
                  style="margin: 0px"
                  class="p-0 mb-1"
                >
                  <span
                    class="text-primary mr-2"
                    style="font-size:18px"
                  >
                    <strong>{{ dataConvocatoria.nombre }}</strong>
                  </span>
                  <b-badge
                    pill
                    :variant="`light-${resolveEstadoVariant(dataConvocatoria.estado)}`"
                    class="text-capitalize px-1 ml-1 mb-0"
                  >
                    {{ dataConvocatoria.estado }}
                  </b-badge>
                </div>
                <span
                  v-for="(val,index) in dataConvocatoria.serviciosx"
                  :key="index"
                  style="margin-bottom:6px; padding: 5px 10px;"
                  class="rounded"
                >
                  <span class="justify-content-center">
                    <feather-icon
                      :icon="resolveServicioIcon(val.codigo)"
                      size="14"
                      class=""
                      :class="`text-${resolveServicioVariant(val.codigo)}`"
                    />
                  </span>
                  <span style="font-size:12px"> {{ val.nombre }} </span>
                  ( <small>N° Becas:</small> <strong>{{ val.n_becas }}</strong> )
                </span>
              </b-media-body>
            </b-media>

          </div>
        </b-col>

      </b-row>
    </b-card>

    <obp-tabla-postulantes
      @actualizarTablaPostulantesEvaluados="actualizarTablaPostulantesEvaluados"
      @asignarCantPostulantesPorEvaluar="(val) => cantPostulantesPorEvaluar = val"
    />

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge,
  BMedia, BAvatar, BMediaAside, BMediaBody,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import opbPostulantesStoreModule from './opb_postulantesStoreModule'

import obpTablaPostulantes from './opb_TablaPostulantes.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
    obpTablaPostulantes,

  },
  data() {
    return {
      dataConvocatoria: {},
      idFormulario: 0,
      idPostulante: 0,

    }
  },
  created() {
    store.dispatch('opb-postulantes-store-module/getConvocatoria', this.$router.currentRoute.params.id).then(res => {
      this.dataConvocatoria = res.data
    }).catch(error => {
      if (error.response.status >= 400 && error.response.status <= 499) {
        this.$router.push({ name: 'social-convocatorias-list' })
      }
    })
  },
  methods: {
    actualizarDataConvocatoria() {
      store.dispatch('opb-postulantes-store-module/getConvocatoria', this.$router.currentRoute.params.id).then(res => {
        this.dataConvocatoria = res.data
      })
    },
    obtenerTotalPostulantes() {
      this.$http.get(`/servicio-social/beca/convocatoria/${this.$router.currentRoute.params.id}/postulante`)
        .then(res => {
          this.totalRows = res.data.total
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Error en actualizar convocatoria!',
              icon: 'BellIcon',
              text: 'Se recomienda recargar la página',
              variant: 'danger',
            },
          })
        })
    },
    actualizarTablaPostulantesPorEvaluar() {
      this.$emit('actualizarTablaPostulantesPorEvaluar')
    },
    actualizarTablaPostulantesEvaluados() {
      this.$emit('actualizarTablaPostulantesEvaluados')
    },

    abrirModalArchivo() {
      if (this.dataConvocatoria.estado === 'concluido' || this.dataConvocatoria.estado === 'cancelado') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Error!',
            icon: 'BellIcon',
            text: 'Convocatoria CONCLUIDA o CANCELADO',
            variant: 'danger',
          },
        })
        return
      }
      if (this.dataConvocatoria.estado !== 'en curso') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Error!',
            icon: 'BellIcon',
            text: 'La convocatoria no esta EN CURSO',
            variant: 'danger',
          },
        })
        return
      }
      this.$emit('actualizarArchivoResultado')
      this.$bvModal.show('modal_archivo_resultado')
    },
    concluirConvocatoria() {
      if (this.cantPostulantesPorEvaluar > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            icon: 'BellIcon',
            text: `No puede concluir la convocatoria ya que tiene ${this.cantPostulantesPorEvaluar} postulante(s) sin evaluar`,
            variant: 'warning',
          },
        })
        return
      }

      this.$swal({
        title: '¿Estas seguro concluir la convocatoria?',
        text: '¡No podrá revertir esta acción!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, concluir!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = {
            idConvocatoria: this.$router.currentRoute.params.id,
            dataEstado: { estado: 'concluido' },
          }
          store.dispatch('opb-postulantes-store-module/actualizarEstadoConvocatoria', data).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Éxito!',
              text: '¡La convocatoria ha concluido con éxito!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.$router.push({ name: 'social-convocatorias-list' })
            })
          }).catch(error => {
            if (error.response.status >= 400 && error.response.status <= 499) {
              error.response.data.error.archivo_resultado.forEach(value => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: '¡Advertencia!',
                    text: value,
                    icon: 'XOctagonIcon',
                    variant: 'warning',
                  },
                })
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Falló la acción, error 500',
                  icon: 'XOctagonIcon',
                  variant: 'danger',
                },
              })
            }
          })
        }
      })
    },

  },
  setup() {
    const OPB_POSTULANTES_APP_STORE_MODULE_NAME = 'opb-postulantes-store-module'

    // Register module
    if (!store.hasModule(OPB_POSTULANTES_APP_STORE_MODULE_NAME)) store.registerModule(OPB_POSTULANTES_APP_STORE_MODULE_NAME, opbPostulantesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OPB_POSTULANTES_APP_STORE_MODULE_NAME)) store.unregisterModule(OPB_POSTULANTES_APP_STORE_MODULE_NAME)
    })
    const resolveServicioVariant = value => {
      if (value === 'BECA-COMEDOR') return 'danger'
      if (value === 'BECA-RESIDENCIA') return 'success'
      if (value === 'BECA-BOLSA') return 'info'
      return 'primary'
    }
    const resolveServicioIcon = value => {
      if (value === 'BECA-COMEDOR') return 'CoffeeIcon'
      if (value === 'BECA-RESIDENCIA') return 'HomeIcon'
      if (value === 'BECA-BOLSA') return 'BriefcaseIcon'

      return 'UserIcon'
    }
    const resolveEstadoVariant = status => {
      if (status === 'borrador') return 'primary'
      if (status === 'vigente') return 'success'
      if (status === 'en curso') return 'warning'
      if (status === 'concluido') return 'danger'
      if (status === 'cancelado') return 'danger'
      return 'primary'
    }

    return {
      opbPostulantesStoreModule,
      resolveServicioVariant,
      resolveServicioIcon,
      resolveEstadoVariant,
    }
  },
}
</script>
<style lang="scss">
</style>
