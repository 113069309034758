<template>
  <div>
    <b-modal
      id="modal_formulario_revision_asistenta_social"
      ok-only
      size="lg"
      class="p-5"
      title="Formulario"
      no-close-on-esc
      no-close-on-backdrop
      modal-class="modal-success"
      :hide-footer="true"
    >
      <formulario-vista-lectura
        :id-formulario="idFormulario"
        :id-usuario="`${idUsuario}`"
      />
      <template #modal-footer>
        <div class="w-100" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import FormularioVistaLectura from '@/views/administrador/gestion-formulario/vista-lectura/FormularioVistaLectura.vue'

export default {
  components: {
    FormularioVistaLectura,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // props: {
  //   idFormulario: {
  //     type: Number,
  //     default: 0,
  //   },
  // },
  data() {
    return {
      idUsuario: 0,
      idFormulario: 0,
    }
  },
  created() {
    this.$parent.$on('abrirModalFormularioRevision', this.abrirModalFormularioRevision)
  },
  methods: {
    abrirModalFormularioRevision(data) {
      this.idUsuario = parseInt(data.usuarioId, 10)
      this.idFormulario = parseInt(data.formularioId, 10)
      this.$bvModal.show('modal_formulario_revision_asistenta_social')
    },
  },

}
</script>

<style lang="scss">
</style>
